<template>
  <div>
    <df-page-header :title="$t('SowingsPlanning.home.plan_sowings')">
      <template #actions>
        <df-button
          data-id="home-add-sowing"
          :disabled="disabledActions"
          @click="openAddSowingModal"
        >
          {{ $t('SowingsPlanning.home.add_sowing') }}
        </df-button>
      </template>
    </df-page-header>
    <add-sowing-modal
      v-if="isAddSowingModalOpened"
      @close="closeAddSowingModal"
    />
  </div>
</template>

<script>
import AddSowingModal from '@/modules/sowingsPlanning/components/modal/AddSowingModal.vue'
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfPageHeader from '@/lib/components/Header/DfPageHeader.vue'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'SowingsPlanningHeader',

  components: {
    AddSowingModal,
    DfButton,
    DfPageHeader,
  },

  props: {
    disabledActions: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      isAddSowingModalOpened: false,
    }
  },

  methods: {
    closeAddSowingModal() {
      this.isAddSowingModalOpened = false
    },
    openAddSowingModal() {
      logEvent(events.sowingsPlanningModule.SowingsPlanning.add_sowing)
      this.isAddSowingModalOpened = true
    },
  },
}
</script>
