<template>
  <div class="variety-item">
    <div class="d-flex variety__date">
      <span class="date-id">{{ sowing.id }}</span>
      <span>{{ sowing.date.formatDate() }}</span>
      <v-chip
        v-if="sowing.status_name === 'executed'"
        class="date-chip"
        label
        :color="greenBackground"
      >
        <font-awesome-icon icon="check" :style="{ color: greenIconColor }" />
        <p>{{ $t('SowingsPlanning.variety_list_item.executed') }}</p>
      </v-chip>
    </div>
    <div class="d-flex align-center variety__content">
      <span>{{ programs(sowing.programs) }}</span>
      <div class="d-flex justify-end content__actions">
        <df-dropdown-list
          id="desktop-actions"
          :button-text="$t('SowingsPlanning.variety_list_item.see_plannings')"
          :items="programsActions(sowing.programs)"
        />
        <df-dropdown-list
          id="desktop-actions"
          icon="ellipsis-v"
          :items="sowingActions"
        />
        <df-dropdown-list
          id="mobile-actions"
          icon="ellipsis-v"
          :items="sowingAndProgramsActions(sowing.programs)"
        />
      </div>
    </div>
    <df-delete-modal
      v-if="isDeleteSowingModalOpened"
      persistent
      :text="$t('SowingsPlanning.delete_sowing_modal.text')"
      :title="$t('SowingsPlanning.delete_sowing_modal.title')"
      :width="594"
      @action-click="deleteSowing"
      @close="closeDeleteModal"
    >
      <template #content>
        <div class="df-flex-m delete-sowing__content">
          <p>
            {{ $t('SowingsPlanning.delete_sowing_modal.sowing_date') }}:
            <span>{{ sowing.date.formatDate() }}</span>
          </p>
          <div></div>
          <p>
            {{ $t('SowingsPlanning.delete_sowing_modal.created_programs') }}:
            <span>{{ programs(sowing.programs) }}</span>
          </p>
        </div>
      </template>
    </df-delete-modal>
    <add-sowing-modal
      v-if="isAddSowingModalOpened"
      :sowing-id="sowing.id"
      @close="closeAddSowingModal"
    />
    <df-copy-modal
      v-if="isCopyModalOpened"
      :description="
        $t('SowingsPlanning.copy_sowing_modal.select_field_description')
      "
      :loading="loadingCopyModal"
      :title="$t('SowingsPlanning.copy_sowing_modal.copy_sowing')"
      @action-click="copySowing"
      @close="closeCopyModal"
    >
      <template #card>
        <div class="df-flex-l df-flex-col variety__copy-modal">
          <div class="df-flex-sm align-center modal__title">
            <font-awesome-icon icon="seedling" />
            <h2>
              {{ $t(variety.crop_message_key) }} | {{ variety.variety_name }}
            </h2>
          </div>
          <div class="df-flex-m modal__text">
            <p>
              {{ $t('SowingsPlanning.delete_sowing_modal.sowing_date') }}:
              <span>{{ sowing.date.formatDate() }}</span>
            </p>
            <div></div>
            <p>
              {{ $t('SowingsPlanning.copy_sowing_modal.created_plannings') }}:
              <span>{{ programTitles }}</span>
            </p>
          </div>
        </div>
      </template>
    </df-copy-modal>
    <execute-sowing-modal
      v-if="isExecuteSowingModalOpened"
      :sowing-id="sowing.id"
      @close="closeExecuteSowingModal"
    />
  </div>
</template>

<script>
import AddSowingModal from '@/modules/sowingsPlanning/components/modal/AddSowingModal.vue'
const DfCopyModal = () => import('@/lib/Modal/DfCopyModal.vue')
import DfDeleteModal from '@/lib/Modal/DfDeleteModal.vue'
import DfDropdownList from '@/lib/components/List/Dropdown/DfDropdownList.vue'
import ExecuteSowingModal from '@/modules/sowingsList/components/modal/ExecuteSowingModal.vue'
import sowingsPlanningService from '@/modules/sowingsPlanning/services/sowingsPlanningService.js'
import { logEvent, events } from '@/services/analytics'
import { mapGetters } from 'vuex'

export default {
  name: 'VarietyItem',

  components: {
    AddSowingModal,
    DfCopyModal,
    DfDeleteModal,
    DfDropdownList,
    ExecuteSowingModal,
  },

  props: {
    sowing: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      isAddSowingModalOpened: false,
      isCopyModalOpened: false,
      isDeleteSowingModalOpened: false,
      isExecuteSowingModalOpened: false,
      loadingCopyModal: false,
      programTitles: '',
    }
  },

  inject: {
    requestFieldSowingsPlanning: { from: 'requestFieldSowingsPlanning' },
    variety: { from: 'variety' },
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    greenBackground() {
      return '#D8F2DC'
    },
    greenIconColor() {
      return '#39AF49'
    },
    sowingActions() {
      return [
        {
          name: this.$t(
            'SowingsPlanning.variety_list_item.sowing_actions.edit'
          ),
          icon: 'edit',
          action: () => {
            logEvent(events.sowingsPlanningModule.SowingsPlanning.edit_sowing)
            this.isAddSowingModalOpened = true
          },
        },
        {
          name: this.$t(
            'SowingsPlanning.variety_list_item.sowing_actions.execute_in_season'
          ),
          icon: 'tractor',
          action: () => {
            logEvent(
              events.sowingsPlanningModule.SowingsPlanning.execute_in_season
            )
            this.isExecuteSowingModalOpened = true
          },
        },
        {
          name: this.$t(
            'SowingsPlanning.variety_list_item.sowing_actions.copy_to'
          ),
          icon: 'copy',
          action: () => {
            logEvent(events.sowingsPlanningModule.SowingsPlanning.copy)
            this.isCopyModalOpened = true
          },
        },
        {
          name: this.$t('SowingsPlanning.variety_list_item.delete'),
          color: '#F23528',
          icon: 'trash',
          action: () => {
            logEvent(events.sowingsPlanningModule.SowingsPlanning.delete_sowing)
            this.isDeleteSowingModalOpened = true
          },
        },
      ]
    },
  },

  methods: {
    closeAddSowingModal() {
      this.isAddSowingModalOpened = false
    },
    closeCopyModal() {
      this.isCopyModalOpened = false
    },
    closeDeleteModal() {
      this.isDeleteSowingModalOpened = false
    },
    closeExecuteSowingModal() {
      this.isExecuteSowingModalOpened = false
    },
    async copySowing(selectedFieldIds) {
      if (!selectedFieldIds.length) {
        this.$root.$emit(
          'notify',
          'warning',
          this.$t('SowingsPlanning.exceptions.no_field_selected')
        )
        return
      }
      const params = {
        field_ids: selectedFieldIds,
        sowing_id: this.sowing.id,
      }
      this.loadingCopyModal = true
      try {
        await sowingsPlanningService.copySowingPlanning(params)
        this.$root.$emit(
          'notify',
          'success',
          this.$t('SowingsPlanning.copy_sowing_modal.successful_request_title')
        )
        logEvent(events.sowingsPlanningModule.SowingsPlanning.finish_copy)
        this.requestFieldSowingsPlanning()
        this.closeCopyModal()
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingCopyModal = false
      }
    },
    async deleteSowing() {
      try {
        const params = {
          sowing_id: this.sowing.id,
        }
        await sowingsPlanningService.deleteSowing(params)
        this.$root.$emit(
          'notify',
          'success',
          this.$t('SowingsPlanning.delete_sowing_modal.sowing_date_deleted')
        )
        this.requestFieldSowingsPlanning()
        this.closeDeleteModal()
      } catch (error) {
        console.error(error)
      }
    },
    programs(programs) {
      if (programs.length) {
        const programTitles = programs.reduce((acc, program) => {
          return (
            acc +
            `${this.$t(
              `SowingsPlanning.${program.phitosanitary_category_message_key}`
            )}/`
          )
        }, '')
        this.programTitles = programTitles.slice(0, -1)
      } else {
        this.programTitles = this.$t('SowingsPlanning.variety_list_item.none')
      }
      return this.programTitles
    },
    programsActions(programs) {
      const diseaseObj = {
        key: 'phytosanitary_category.disease',
        name: this.$t('SowingsPlanning.phytosanitary_category.disease'),
        quantity: 0,
        action: () => {
          // CM: falta passar talhão e variety para receber na outra tela
          this.$router.replace({
            path: 'diseases_control',
            query: { fazenda_id: this.currentFarmId },
          })
        },
      }
      const weedObj = {
        key: 'phytosanitary_category.weed',
        name: this.$t('SowingsPlanning.phytosanitary_category.weed'),
        quantity: 0,
        action: () => {
          // CM: falta passar talhão e variety para receber na outra tela
          this.$router.replace({
            path: 'diseases_control',
            query: { fazenda_id: this.currentFarmId },
          })
        },
      }
      programs.forEach((program) => {
        if (diseaseObj.key == program.phitosanitary_category_message_key) {
          diseaseObj.quantity += 1
        } else if (weedObj.key == program.phitosanitary_category_message_key) {
          weedObj.quantity += 1
        }
      })
      const programsQttArray = [diseaseObj, weedObj]

      return programsQttArray
    },
    sowingAndProgramsActions(programs) {
      return [...this.programsActions(programs), ...this.sowingActions]
    },
  },
}
</script>

<style lang="scss" scoped>
.variety-item {
  .variety__date {
    align-items: center;
    gap: 24px;

    @include d(t) {
      gap: 12px;
    }
    @include d(m) {
      flex-direction: column;
      align-items: start;
      gap: 4px;
    }
    .date-id {
      @include d(m) {
        display: none;
      }
    }
    .date-chip {
      padding: 4px 8px;
      height: 25px;

      p {
        color: #39af49;
        font-weight: 500;
        margin-left: 8px;

        @include d(m) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
  .variety__content {
    justify-content: space-between;
    gap: 16px;

    @include d(m) {
      justify-content: end;
    }
    .content__actions {
      gap: 16px;
    }
  }
}
.variety__copy-modal {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #e6e9e6;
  background: #f8f9f8;

  .modal__title {
    svg {
      height: 14px;
      width: 14px;
      color: #1a2b46;
    }
    h2 {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
  .modal__text {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    p {
      font-weight: 500;
      line-height: 16px;
    }
    div {
      width: 1px;
      background-color: #e6e9e6;
    }
    span {
      font-weight: 400;
      line-height: 18px;
    }
  }
}
.delete-sowing__content {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #e6e9e6;
  background: #f8f9f8;
  color: #5b6459;
  font-family: 'Rubik';
  font-size: 14px;
  font-style: normal;
  p {
    font-weight: 500;
    line-height: 16px;
  }
  div {
    width: 1px;
    background-color: #e6e9e6;
  }
  span {
    font-weight: 400;
    line-height: 18px;
  }
}
</style>
